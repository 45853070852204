import type { FormEvent } from 'react';
import { useCallback, useRef, useState } from 'react';

import { authApi } from '@import-io/js-sdk';
import { useRecurly } from '@recurly/react-recurly';
import { Form } from 'antd';

import { getRecaptchaToken } from 'common/google-recaptcha';
import type { SignupFormData, UseFinish, UseFinishParams, UseSubmitForm } from 'features/signup/signup-types';
import { getErrorMessage } from 'features/signup/signup-utils';

export const useSubmitForm = (): UseSubmitForm => {
  const ref = useRef<HTMLFormElement>(null);
  const [form] = Form.useForm<SignupFormData>();
  const submit = form.submit;

  const onSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      submit();
    },
    [submit],
  );

  return {
    form: form,
    onSubmit: onSubmit,
    formRef: ref,
  };
};

export const useFinish = ({ formRef, messageApi }: UseFinishParams): UseFinish => {
  const [isSuccess, setIsSuccess] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const recurly = useRecurly();
  const getRecurlyToken = useCallback(
    () =>
      new Promise<string>((res, rej) => {
        recurly.token(formRef.current as HTMLFormElement, (err, token) => {
          if (err) {
            rej(err);
          } else {
            res(token.id);
          }
        });
      }),
    [formRef, recurly],
  );

  const onFinish = useCallback(
    async (values: SignupFormData) => {
      try {
        setIsSaving(true);
        const { card, ...form } = values;
        const recurlyToken = card.empty ? null : await getRecurlyToken();
        await authApi.signUp({ ...form, recurlyToken: recurlyToken, captcha: await getRecaptchaToken() });
        setIsSuccess(true);
      } catch (e: unknown) {
        void messageApi.error(getErrorMessage(e));
      } finally {
        setIsSaving(false);
      }
    },
    [getRecurlyToken, messageApi, setIsSaving],
  );

  return {
    error: '',
    isLoading: isSaving,
    isSuccess: isSuccess,
    onFinish: onFinish,
  };
};
